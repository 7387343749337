import React from 'react';
import {graphql} from "gatsby";
import Markdown from 'markdown-to-jsx';

import Layout from "../../components/layouts/Layout";
import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";

import Col from "../../components/styled/grid/Col";
import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";

import circuit2 from "../../../static/assets/animations/circuit/circuit01.json";

const GovernmentalSolutions = ({data}) => {

    const {lang, content, SEO} = data.governmental

    return (
        <Layout lang={lang} seo={SEO}>
            <LottieAnimation animationData={circuit2} left="true"/>
            <SectionLayoutGrid customBackground="middle">
                <Col className="markdown">
                    <Markdown children={content}/>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    );
};

export default GovernmentalSolutions;

export const query = graphql`
   query GetSingleGovernmentalSolution($locale: String) {
         governmental: strapiGovernmentalSolutions(lang: { eq: $locale }) {
            lang
            content
            SEO {
                title
                isIndexable
                description
                keywords
                preview {
                  url
                }
            }
         }
   }
 `